import React from 'react';
import {
  ComponentPreview,
  DontDo,
  DontDoItem,
  List,
  Link,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  PlatformTableRow,
  Section,
  SectionSubhead,
} from 'docComponents';
import pageHeroData from '../../../../data/pages/components.yml';
import floatingActionPreviewData from '../../../../data/previews/floating-action.yml';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sizeSelect: 'medium',
    };
  }

  handleSizeChange = () => {
    this.setState({
      sizeSelect: this.state.sizeSelect === 'medium' ? 'large' : 'medium',
    });
  };
  render() {
    return (
      <PageWithSubNav
        pageType="design"
        title="Floating Action"
        designCode
        isMobileOnly
        subnav="components">
        <PageHero
          heroData={pageHeroData}
          tierTwo="Buttons"
          tierThree="Floating Action"
        />

        <PageNavigation>
          <PageNavigationLink>Size</PageNavigationLink>
          <PageNavigationLink>Position</PageNavigationLink>
          <PageNavigationLink>Platform</PageNavigationLink>
        </PageNavigation>

        <Section title="Size">
          <Paragraph>
            The floating action has two sizes, medium and large.
          </Paragraph>
          <ComponentPreview
            name="PatternsContainer"
            hideEnvironmentToggle
            previewData={floatingActionPreviewData.size}
            onChange={this.handleSizeChange}>
            {this.state.sizeSelect === 'medium' && (
              <img
                width="470"
                src={require('../../../../imgs/previews/preview-fab-medium.jpg')}
              />
            )}
            {this.state.sizeSelect === 'large' && (
              <img
                src={require('../../../../imgs/previews/preview-fab-large.jpg')}
              />
            )}
          </ComponentPreview>
        </Section>

        <Section title="Position">
          <List>
            <li>
              <strong>Location:</strong> The floating action button (FAB)
              appears at the bottom right of the interface in which the action
              takes place.
            </li>
            <li>
              <strong>Elevation:</strong> Consistent with our{' '}
              <Link href="/guidelines/layering/elevation/design#Stack%20Order">
                stack order
              </Link>
              , the FAB has a level 2 elevation.
            </li>
          </List>
          <SectionSubhead>Icon-Only</SectionSubhead>
          <Paragraph>
            This button is always icon-only, and should never have a label used
            in its place.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="use a label to explain an action."
              img="floating-action-button-icon-only-dont"
            />
            <DontDoItem
              type="do"
              text=" use icons for adding or sharing."
              img="floating-action-button-icon-only-do"
            />
          </DontDo>
          <SectionSubhead>Actions</SectionSubhead>
          <Paragraph>
            The floating action button (FAB) should only initiate an action.
            Never use it to complete a workflow.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="use the button to modify or save content."
              img="floating-action-button-action-modify-dont"
            />
            <DontDoItem
              type="do"
              text="use it to initiate something constructive."
              img="floating-action-button-action-modify-do"
            />
          </DontDo>
          <Paragraph>
            Avoid using the FAB for bulk or destructive actions and instead
            place them elsewhere to avoid confusion.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="delete or remove content."
              img="floating-action-button-action-secondary-dont"
            />
            <DontDoItem
              type="do"
              text="place secondary actions in other areas of the interface."
              img="floating-action-button-action-secondary-do"
            />
          </DontDo>
          <Paragraph>
            Important, primary actions make use of our action color, which
            demands a lot of attention. It’s best to limit its use to just one.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="use for multiple actions in an interface."
              img="floating-action-button-one-dont"
            />
            <DontDoItem
              type="do"
              text="reserve for the single, most important action."
              img="floating-action-button-one-do"
            />
          </DontDo>
          <SectionSubhead>Position</SectionSubhead>
          <Paragraph>
            The floating action button should remain fixed and not be placed
            haphazardly.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="place the button overtop meaningful content."
              img="floating-action-button-position-dont"
            />
            <DontDoItem
              type="do"
              text="keep its placement consistent to the bottom right."
              img="floating-action-button-position-do"
            />
          </DontDo>
          <SectionSubhead>Mobile-Only</SectionSubhead>
          <Paragraph>
            As exciting as this button may be, avoid using it for anything other
            than mobile applications.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="use the floating action button for web-only workflows."
              img="floating-action-button-mobile-only-dont"
            />
            <DontDoItem
              type="do"
              text="use it for Apple and Android devices."
              img="floating-action-button-mobile-only-do"
            />
          </DontDo>
        </Section>

        <Section title="Platform">
          <PlatformTable footerMessage="No guidelines exist for Windows (yet).">
            <PlatformTableRow platform="web">
              <List>
                <li>
                  Follow Uniform’s{' '}
                  <Link href="/components/buttons/button/design/">
                    button guidelines
                  </Link>
                  .
                </li>
              </List>
            </PlatformTableRow>
            <PlatformTableRow platform="apple">
              <List>
                <li>
                  Stick to the guidelines above in addition to the{' '}
                  <Link href="https://developer.apple.com/design/human-interface-guidelines/ios/bars/navigation-bars/">
                    navigation bar
                  </Link>{' '}
                  guidelines for text title button controls.
                </li>
              </List>
            </PlatformTableRow>
            <PlatformTableRow platform="android" />
          </PlatformTable>
        </Section>
      </PageWithSubNav>
    );
  }
}
